.tagContent {
  width: 1042px;
}

.cardItem:hover .cardItem_title {
  color: #49a9a7;
}

.cardItem_title {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 30px;
  color: #333;
}

.item_desc {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #999;
  word-break: break-world;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.item_three_clamp {
  word-break: break-world!important;
  overflow : hidden!important;
  text-overflow: ellipsis!important;
  display: -webkit-box!important;
  -webkit-line-clamp: 3!important;
  -webkit-box-orient: vertical!important;
}

.footer {
  background-image: url("./assets/footer_bg.svg");
  background-position: center center;
  background-size: cover;
}

.footer_title {
  color: #fff;
}

.submitBtn {
  width: 160px;
  height: 40px;
  border-radius: 35px !important;
}

.toggleBtnWrap {
  height: 100%;
}

.toggleBtn > svg {
  color: #cfcfcf;
}

.toggleBtn:hover {
  background-color: #49a9a7;
}

.toggleBtn:hover > svg {
  color: #fff;
}

.toggleBtn > svg {
  color: #fff;
}

@media screen and (min-width: 600px) {
  .skeletonHotCard,
  .hotCard {
    height: 92px;
  }
  .hotCard > img {
    height: 92px;
    width: 195px;
  }
  .hotCard:hover {
    box-shadow: 0px 8px 32px 0px #cdd3df;
  }

  .cardItem:hover {
    background-color: #f8f8f8;
  }
  .toggleBtn {
    width: 48px;
    height: 48px;
    cursor: pointer;
    background-color: #49a9a7;
  }
}

@media screen and (max-width: 600px) {
  .skeletonHotCard,
  .hotCard {
    height: 78px;
  }
  .hotCard > img {
    height: 78px;
    width: 165.31px;
  }
  .toggleBtn {
    width: 100%;
    height: 48px;
    cursor: pointer;
    /* background-color: #f8f8f8 */
    background-color: #49a9a7;
  }
}
